import { z } from "zod"

const CommonData = z.object({
  sermonId: z.number(),
  start: z
    .string()
    .refine((value) => {
      if (value === null || value === undefined) {
        return true
      }
      return value.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/) !== null
    })
    .or(z.null()),
  end: z
    .string()
    .refine((value) => {
      if (value === null || value === undefined) {
        return true
      }
      return value.match(/^[0-9]{2}:[0-9]{2}:[0-9]{2}$/) !== null
    })
    .or(z.null()),
  crop: z
    .object({
      aspectRatio: z.enum(["16:9", "9:16", "1:1"]),
    })
    .nullish(),
})

const TrimJob = z.object({
  type: z.literal("trim"),
  videoUrl: z.string(),
})

const YoutubeJob = z.object({
  type: z.literal("youtube"),
  youtubeUrl: z.string(),
})

export const JobSchema = z.object({
  id: z.string(),
  data: z.intersection(
    CommonData,
    z.discriminatedUnion("type", [TrimJob, YoutubeJob])
  ),
})

export type Job = z.infer<typeof JobSchema>
export type TrimPoints = Pick<Job["data"], "start" | "end">
export const aspectRatio = z.enum(["16:9", "9:16", "1:1"])
export type AspectRatio = z.infer<typeof aspectRatio>

export const Segment = z.object({
  url: z.string().url(),
  // indexed to full audio timeline
  startFromFrame: z.number(),
  endFrame: z.number(),
})

export const SermonJob = z.object({
  sermonId: z.number(),
  aspectRatio,
  processedVideoUrl: z.string().url(),
  audioUrl: z.string().url(),
  renderVideoUrl: z.string().url(),
  // videoSegments: z.array(segment),
  audioSegments: z.array(Segment),
})
export type SermonJob = z.infer<typeof SermonJob>
