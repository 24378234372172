export interface AudiogramStyleConfig {
  readonly name: string
  readonly id: string
  readonly thumbnailUrl: string
  supportsMirroring: boolean
}

export const hillsAudiogramStyleConfig = {
  name: "Hills",
  id: "hills",
  thumbnailUrl: "/audiograms/hills.png",
  supportsMirroring: false,
} as const satisfies AudiogramStyleConfig
export const barsAudiogramStyleConfig = {
  name: "Bars",
  id: "bars",
  thumbnailUrl: "/audiograms/bars.png",
  supportsMirroring: true,
} as const satisfies AudiogramStyleConfig
export const radialBarsAudiogramStyleConfig = {
  name: "Radial Bars",
  id: "radial-bars",
  thumbnailUrl: "/audiograms/radial.png",
  supportsMirroring: false,
} as const satisfies AudiogramStyleConfig
export const waveAudiogramStyleConfig = {
  name: "Wave",
  id: "wave",
  thumbnailUrl: "/audiograms/wave.png",
  supportsMirroring: false,
} as const satisfies AudiogramStyleConfig

export const replicatedWavesAudiogramStyleConfig = {
  name: "Replicated Waves",
  id: "replicated-waves",
  thumbnailUrl: "/audiograms/replicated-waves.png",
  supportsMirroring: false,
} as const satisfies AudiogramStyleConfig

export const monoHillAudiogramStyleConfig = {
  name: "Mono Hill",
  id: "mono-hill",
  thumbnailUrl: "/audiograms/mono-hill.png",
  supportsMirroring: false,
} as const satisfies AudiogramStyleConfig

export const audiogramStyles = {
  [hillsAudiogramStyleConfig.id]: hillsAudiogramStyleConfig,
  [barsAudiogramStyleConfig.id]: barsAudiogramStyleConfig,
  [radialBarsAudiogramStyleConfig.id]: radialBarsAudiogramStyleConfig,
  [waveAudiogramStyleConfig.id]: waveAudiogramStyleConfig,
  [replicatedWavesAudiogramStyleConfig.id]: replicatedWavesAudiogramStyleConfig,
  [monoHillAudiogramStyleConfig.id]: monoHillAudiogramStyleConfig,
} as const satisfies Record<string, AudiogramStyleConfig>
