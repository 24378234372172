import { Segment } from "@/features/trimmer/types/job"
import { z } from "zod"

import { nestedElementSchema } from "@/lib/validations/element"

const wordSchema = z.object({
  id: z.string(),
  word: z.string(),
  start: z.number(),
  end: z.number(),
})

export const wordsSchema = z.array(wordSchema)

const commonProperties = z.object({
  // urls that should ONLY be used for rendering. never serving on app
  sermonVideoUrl: z.string(), // either renderUrl or serveUrl depending on frontend/lambda
  sermonServeUrl: z.string().optional(),
  sermonRenderUrl: z.string().optional(),
  sermonAudioUrl: z.string(),
  // nullish for legacy behavior
  audioSegments: z.array(Segment).optional().default([]),
  // only when contentType = image
  quoteText: z.string().optional(),
})

export const inputPropsSchema = z.object({
  durationInFrames: z.number(),
  contentType: z.enum(["image", "video"]),
  commonProperties,
  clippingBasedProperties: z.object({
    frameRanges: z
      .array(z.tuple([z.number(), z.number()]))
      // FIXME: remove shim
      .default([[0, 30 * 10]]),
    chunks: z
      .array(
        z.object({
          words: wordSchema
            .merge(
              z.object({
                start: z.number(),
                end: z.number(),
              })
            )
            .array(),
          id: z.string(),
          text: z.string(),
          start: z.number(),
          end: z.number(),
        })
      )
      // FIXME: remove shim
      .default([]),
  }),
  isBufferingDisabled: z.boolean().optional(),
})

export const savedInputPropsSchema = inputPropsSchema

export const usedInputPropsSchema = z.object({
  ...inputPropsSchema.shape,
  commonProperties: z.object({
    ...commonProperties.shape,
    elements: nestedElementSchema,
  }),
})
