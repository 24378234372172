import { El } from "@/features/remotion/components/element"
import { AbsoluteFill } from "remotion"
import { z } from "zod"

import { cn } from "@/lib/utils"
import { getPageFromElementTree } from "@/lib/utils/element-tree"
import { usedInputPropsSchema } from "@/lib/validations/input-props"

export const BaseComp = (
  props: z.infer<typeof usedInputPropsSchema>
): JSX.Element => {
  const pageEl = getPageFromElementTree(props.commonProperties.elements)
  return (
    <AbsoluteFill className={cn("bg-black")}>
      <El el={pageEl} key={pageEl.id} props={props} />
    </AbsoluteFill>
  )
}
