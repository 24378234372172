import { z } from "zod"

import { PAGE_NODE_ID } from "@/lib/constants/elements"
import {
  flatElementSchema,
  nestedElementSchema,
} from "@/lib/validations/element"
import { ElementIdsTree } from "@/lib/validations/element-tree"

export function getNestedElementTreeFromFlatElementTree<
  FlatElement extends z.infer<typeof flatElementSchema>,
>(elements: FlatElement[], elementIdsTree: ElementIdsTree) {
  const [parentId, childrenIdsTree] = elementIdsTree

  const flatParentTarget = elements.find((element) => element.id === parentId)
  if (!flatParentTarget) {
    throw new Error(
      `Parent element with id ${parentId} not found, elements: ${JSON.stringify(elements, null, 2)}, elementIdsTree: ${JSON.stringify(elementIdsTree, null, 2)}`
    )
  }

  const childrenElements =
    childrenIdsTree != null
      ? {
          children: childrenIdsTree.map((childIdsTree) =>
            getNestedElementTreeFromFlatElementTree(elements, childIdsTree)
          ),
        }
      : {}

  try {
    const parsed = nestedElementSchema.safeParse(
      {
        ...childrenElements,
        ...flatParentTarget,
      },
      {
        errorMap: () => {
          return {
            message: `Error while traversing the element tree: A parent with children ${JSON.stringify(
              childrenIdsTree,
              null,
              2
            )}, id ${parentId} and type ${flatParentTarget.type} is not a nestable element`,
          }
        },
      }
    )
    if (parsed.success) return parsed.data
    else if (parsed.error) console.debug(parsed.error)
    return {
      ...flatParentTarget,
      children: [],
    }
  } catch (error) {
    console.error(`Error parsing element with id ${parentId}:`, error)
    throw error
  }
}

export function flattenNestedElementTree(
  element: z.infer<typeof nestedElementSchema>
): {
  flatElements: z.infer<typeof flatElementSchema>[]
  elementIdsTree: ElementIdsTree
} {
  const flatElements: z.infer<typeof flatElementSchema>[] = []
  const elementIdsTree: ElementIdsTree = [element.id, null]

  function traverse(
    element: z.infer<typeof nestedElementSchema>,
    idsTree: ElementIdsTree
  ) {
    const { ...flatElement } = element
    flatElements.push(flatElement as z.infer<typeof flatElementSchema>)

    if ("children" in flatElement && flatElement.children) {
      idsTree[1] = []
      for (const child of flatElement.children) {
        const childIdsTree: ElementIdsTree = [child.id, null]
        idsTree[1]?.push(childIdsTree)
        traverse(child, childIdsTree)
      }
    }
  }

  traverse(element, elementIdsTree)
  return { flatElements, elementIdsTree }
}

export function recursivelyFindElementWithType(
  element: z.infer<typeof nestedElementSchema>,
  type: string
): z.infer<typeof nestedElementSchema> | null {
  if (element.type === type) return element
  if ("children" in element && element.children) {
    for (const child of element.children) {
      const found = recursivelyFindElementWithType(child, type)
      if (found) return found
    }
  }
  return null
}

export function getPageFromElementTree(
  elements: z.infer<typeof nestedElementSchema>
): Extract<z.infer<typeof nestedElementSchema>, { type: "frame" }> {
  const documentEl = elements
  if (documentEl.type !== "document")
    throw new Error(
      `First element encountered was not document, instead it was: ${documentEl.type}`
    )

  const firstPageEl = documentEl.children?.[0]
  if (!firstPageEl) throw new Error(`No children found under document element`)

  if (firstPageEl.id !== PAGE_NODE_ID)
    throw new Error(
      `First element in document encountered not page or frame, instead had id ${firstPageEl.id} and type ${firstPageEl.type}`
    )

  if (!isFrameElement(firstPageEl))
    throw new Error(
      `First element in document encountered not frame, instead had id ${firstPageEl.id} and type ${firstPageEl.type}`
    )

  return firstPageEl
}

export function isFrameElement(
  element: z.infer<typeof nestedElementSchema>
): element is Extract<z.infer<typeof nestedElementSchema>, { type: "frame" }> {
  return element.type === "frame"
}

export function deleteElementIdFromElementIdsTree(
  elementIdsTree: ElementIdsTree,
  elementId: string
) {
  const [parentId, childrenIdsTree] = elementIdsTree

  if (parentId === elementId)
    throw new Error(`Cannot delete root element with id ${elementId}`)

  if (childrenIdsTree) {
    for (let i = 0; i < childrenIdsTree.length; i++) {
      const childTree = childrenIdsTree[i]
      if (childTree[0] === elementId) {
        childrenIdsTree.splice(i, 1) // Remove the child with the matching id
        return elementIdsTree
      } else {
        const result = deleteElementIdFromElementIdsTree(childTree, elementId)
        if (result !== childTree) return elementIdsTree
      }
    }
  }

  console.warn(`Element with id ${elementId} not found in the tree`)
  return elementIdsTree
}

export const findParentId = (
  elementIdsTree: ElementIdsTree,
  elementId: string
): string | null => {
  const [parentId, childrenIdsTree] = elementIdsTree

  if (childrenIdsTree) {
    for (const childTree of childrenIdsTree) {
      if (childTree[0] === elementId) return parentId
      const result = findParentId(childTree, elementId)
      if (result) return result
    }
  }
  return null
}

export function moveChildToParentId(
  elementIdsTree: ElementIdsTree,
  parentId: string,
  childId: string
): ElementIdsTree {
  deleteElementIdFromElementIdsTree(elementIdsTree, childId)

  return addChildIdToParentId(elementIdsTree, parentId, childId)
}

export function addChildIdToParentId(
  elementIdsTree: ElementIdsTree,
  parentId: string,
  childId: string
): ElementIdsTree {
  const [currentParentId, childrenIdsTree] = elementIdsTree

  if (currentParentId === parentId) {
    if (!childrenIdsTree) {
      elementIdsTree[1] = [[childId, null]]
    } else {
      childrenIdsTree.push([childId, null])
    }
    return elementIdsTree
  }

  if (childrenIdsTree) {
    for (const childTree of childrenIdsTree) {
      const result = addChildIdToParentId(childTree, parentId, childId)
      if (result) {
        return elementIdsTree
      }
    }
  }

  console.warn(`Parent with id ${parentId} not found in the tree`)
  return elementIdsTree
}

export function moveElementInTree(
  elementIdsTree: ElementIdsTree,
  elementId: string,
  position: "forward" | "backward" | "toFront" | "toBack"
): ElementIdsTree {
  const [, childrenIdsTree] = elementIdsTree

  if (!childrenIdsTree) {
    console.warn(`Element with id ${elementId} not found in the tree`)
    return elementIdsTree
  }

  const index = childrenIdsTree.findIndex(([id]) => id === elementId)

  if (index === -1) {
    for (const childTree of childrenIdsTree) {
      const result = moveElementInTree(childTree, elementId, position)
      if (result !== childTree) {
        return elementIdsTree
      }
    }
    console.warn(`Element with id ${elementId} not found in the tree`)
    return elementIdsTree
  }

  const [element] = childrenIdsTree.splice(index, 1)

  switch (position) {
    case "forward":
      childrenIdsTree.splice(
        Math.min(index + 1, childrenIdsTree.length),
        0,
        element
      )
      break
    case "backward":
      childrenIdsTree.splice(Math.max(index - 1, 0), 0, element)
      break
    case "toFront":
      childrenIdsTree.push(element)
      break
    case "toBack":
      childrenIdsTree.unshift(element)
      break
  }

  return elementIdsTree
}
