import { z } from "zod"

import { aspectRatioSchema } from "@/lib/validations/store"

export const TEMPLATE_IDS_TO_USE_IN_PIPELINE = {
  photo: [20, 21, 22, 30, 31, 44, 51, 52, 53, 54, 55],
  reel: [1, 10, 12, 23, 37, 43, 45, 48, 50],
  video: [1, 10, 12, 23, 37, 43, 45, 48, 50],
  story: [1, 10, 12, 23, 37, 43, 45, 48, 50],
} as const satisfies Record<string, number[]>

export const initialShadow = {
  x: 0,
  y: 8,
  blur: 12,
  color: "#00000040",
  spread: 9,
} as const

export const SENTENCE_TERMINATORS = [
  ".",
  "?",
  "!",
  "।",
  "॥",
  "…",
  "‥",
  "。",
  "．",
  "？",
  "！",
] as const satisfies string[]

export const DIMENSIONS = {
  "16:9": {
    w: 712,
    h: 400,
  },
  "1:1": {
    w: 712,
    h: 712,
  },
  "9:16": {
    w: 400,
    h: 712,
  },
} as const satisfies Record<
  z.infer<typeof aspectRatioSchema>,
  { w: number; h: number }
>

export const SERMON_SHOTS_TO_RENDER = 6
export const SERMON_SHOTS_MINIMUM_SIZE = 35 * 1024 // 35kb
