import { Shadow } from "@/lib/validations/styles"

export function getStringifiedShadow(shadow: Shadow): string {
  const { x = 0, y = 0, blur = 0, color } = shadow ?? {}

  return [
    x,
    y,
    blur,
    // spread,
    color,
  ]
    .filter(<T>(v: T): v is NonNullable<T> => v !== null && v !== undefined)
    .map(toPx)
    .join(" ")
}
const toPx = (n: number | string): string | number =>
  typeof n === "number" && n !== 0 ? `${n}px` : n

export function isValidHexColor(hex: string): boolean {
  const regex = /^[A-Fa-f0-9]{6}([A-Fa-f0-9]{2})?$/
  return regex.test(hex)
}
