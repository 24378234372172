import { ComponentProps, useEffect, useState } from "react"
import { continueRender, delayRender } from "remotion"
import { z } from "zod"

import { top250GoogleFonts } from "@/lib/fonts"
import { cn } from "@/lib/utils"

import { baseTextPropsSchema } from "./validation"

export const Text = (
  props: z.infer<typeof baseTextPropsSchema> & ComponentProps<"span">
) => {
  const [handle] = useState(() =>
    delayRender("Loading font for text element", {
      timeoutInMilliseconds: 7000 * 2,
      retries: 4,
    })
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (props.style?.fontFamily) {
        const font = top250GoogleFonts.find(
          (availableFont) => availableFont.family === props.style?.fontFamily
        )
        if (font) {
          const loaded = await font.load()
          // @ts-expect-error - hack to prevent loading @remotion/google-fonts lib
          loaded.loadFont()
        }
      }

      continueRender(handle)
    })()
  }, [handle, props.style?.fontFamily, props.style?.fontWeight])

  return (
    <span
      {...props}
      style={{ ...props.style }}
      className={cn("w-full whitespace-pre-line px-2 py-1", props.className)}
    >
      {props.content}
    </span>
  )
}
