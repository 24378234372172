import { z } from "zod"

import {
  defaultRequiredElements,
  getDefaultElementIdsTree,
} from "@/lib/constants/elements"
import { getNestedElementTreeFromFlatElementTree } from "@/lib/utils/element-tree"
import { usedInputPropsSchema } from "@/lib/validations/input-props"

export const defaultProps = {
  clippingBasedProperties: {
    frameRanges: [[0, 1]],
    chunks: [],
  },
  commonProperties: {
    audioSegments: [],
    elements: getNestedElementTreeFromFlatElementTree(
      [
        ...defaultRequiredElements,
        {
          id: "bgOverlay",
          type: "rectangle",
          hidden: false,
          style: {
            backgroundColor: "rgba(30, 58, 138, 0.5)",
            borderRadius: 0,
            backgroundBlur: 0,
          },
          transform: {
            x: 0,
            y: 0,
            width: 712,
            height: 712,

            verticalConstraint: "top",
            horizontalConstraint: "left",
          },
        },
        {
          id: "clipping",
          type: "clipping",
          hidden: false,
          style: {
            borderRadius: 20,
            backgroundBlur: 0,
          },
          transform: {
            width: 512,
            height: 512,
            x: 100,
            y: 100,

            verticalConstraint: "top",
            horizontalConstraint: "left",
          },
        },
        {
          id: "churchLogo",
          type: "image",
          src: "https://s3-editor.postsunday.com/dark-logo.png.1717802991340",
          hidden: false,
          naturalHeight: 1080,
          naturalWidth: 1080,
          name: "Chuch Logo",
          transform: {
            x: 550,
            y: 50,
            width: 100,
            height: 100,

            verticalConstraint: "top",
            horizontalConstraint: "left",
          },
          style: {
            backgroundBlur: 0,
            borderRadius: 1000,
            objectFit: "fill",
          },
        },
        {
          id: "waveform",
          type: "waveform",
          hidden: false,
          transform: {
            x: 40,
            y: 400,
            height: 284,
            width: 632,

            verticalConstraint: "top",
            horizontalConstraint: "left",
          },
          style: {
            backgroundColor: "transparent",
            color: "#f97316",
          },
        },
        {
          id: "subtitle",
          type: "subtitles",
          wordsAtATime: 4,
          hidden: false,
          style: {
            casing: "uppercase",
            fontFamily: "Inter",
            fontWeight: "900",
            fontSize: 40,
            textAlign: "center",
            backgroundColor: "transparent",
            highlightBackgroundColor: "#ef4444",
            color: "#ffffff",
            highlightTextColor: "#ffffff",
            borderRadius: 0,
          },
          subtitleStyleId: "default",
          transform: {
            x: 50,
            y: 600,
            height: 72,
            width: 600,

            verticalConstraint: "top",
            horizontalConstraint: "left",
          },
        },
      ],
      getDefaultElementIdsTree()
    ),
    sermonAudioUrl: "",
    sermonVideoUrl:
      "https://s3-editor.postsunday.com/1721419094421/The_Power_of_Surrender__Hannah_s_Story_1718142747560.mp4",
  },
  contentType: "video",
  durationInFrames: 1,
} as const satisfies z.infer<typeof usedInputPropsSchema>
