import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"
import { z } from "zod"

import { flatElementSchema } from "@/lib/validations/element"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const getElementDomId = (id: string) => `element-${id}`

export const getSnapPositionsFromDimension = ({
  canvasDimensions: dimension,
  otherElements: otherElements,
  isVertical,
  editorScale,
}: {
  canvasDimensions: number
  otherElements?: z.infer<typeof flatElementSchema>[]
  isVertical: boolean
  editorScale: number
}) => {
  const canvasSnapPoints = [dimension, dimension / 2, 0]
  // Get all snap points from other elements
  const otherSnapPoints =
    otherElements
      ?.flatMap((element) => {
        const numericDimensions = getNumericDimensionsFromElement({
          id: element.id,
          element,
          editorScale,
        })

        return [
          ...("transform" in element
            ? isVertical
              ? [
                  [
                    // Edges
                    element.transform?.y,
                    element.transform?.y + numericDimensions.height,
                    // Center
                    element.transform?.y + numericDimensions.height / 2,
                  ],
                ]
              : [
                  [
                    // Edges
                    element.transform?.x,
                    element.transform?.x + numericDimensions.width,
                    // Center
                    element.transform?.x + numericDimensions.width / 2,
                  ],
                ]
            : []),
        ]
      })
      .flat() ?? []

  return [...canvasSnapPoints, ...otherSnapPoints]
}

export const getNumericDimensionsFromElement = ({
  id,
  element,
  editorScale,
}: {
  id: string
  element: z.infer<typeof flatElementSchema>
  editorScale: number
}) => {
  if (!("transform" in element)) return { width: 0, height: 0 }

  if (
    element.transform.width === "auto" ||
    element.transform.height === "auto"
  ) {
    const elementDom =
      typeof window === "undefined" || typeof document === "undefined"
        ? null
        : document?.getElementById(getElementDomId(id))

    if (!elementDom) return { width: 0, height: 0 }

    const numericWidth =
      element.transform.width === "auto"
        ? elementDom.getBoundingClientRect().width / editorScale
        : element.transform.width

    const numericHeight =
      element.transform.height === "auto"
        ? elementDom.getBoundingClientRect().height / editorScale
        : element.transform.height

    return { width: numericWidth, height: numericHeight }
  }

  return { width: element.transform.width, height: element.transform.height }
}

export function getPostsundayBucketCdnLink(url: string) {
  return url
  // return url.replaceAll(
  //   "https://postsunday.s3.us-east-2.amazonaws.com/",
  //   "https://d35hi5lwgp34ax.cloudfront.net/"
  // )
}

export function getRenderBucketCdnLink(url: string) {
  return url
  // return url.replaceAll(
  //   "https://s3.us-east-1.amazonaws.com/remotionlambda-useast1-jfaco6fb25/",
  //   "https://d2osxdwfqg82rz.cloudfront.net/"
  // )
}
