import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { getAudioData, type AudioData } from "@remotion/media-utils"
import { cancelRender, continueRender, delayRender } from "remotion"

interface Props {
  timeoutInMilliseconds?: number
  retries?: number
}

/**
 * @description Wraps the getAudioData() function into a hook and does 3 things:
 * @description Keeps the audio data in a state
 * @description Wraps the function in a delayRender() / continueRender() pattern.
 * @description Handles the case where the component gets unmounted while the fetching is in progress and a React error is thrown.
 * @see [Documentation](https://www.remotion.dev/docs/use-audio-data)
 */
export const useAudioData = (src: string, props: Props): AudioData | null => {
  if (!src) {
    throw new TypeError("useAudioData requires a 'src' parameter")
  }

  const mountState = useRef({ isMounted: true })

  useEffect(() => {
    const { current } = mountState
    current.isMounted = true
    return () => {
      current.isMounted = false
    }
  }, [])

  const [metadata, setMetadata] = useState<AudioData | null>(null)

  const fetchMetadata = useCallback(async () => {
    const handle = delayRender(
      `Waiting for audio metadata with src="${src}" to be loaded`,
      props
    )

    try {
      const data = await getAudioData(src)
      if (mountState.current.isMounted) {
        setMetadata(data)
      }
    } catch (err) {
      cancelRender(err)
    }

    continueRender(handle)
  }, [src, props])

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchMetadata()
  }, [fetchMetadata])

  return metadata
}
