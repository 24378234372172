import { BarsVisualization } from "@/features/remotion/audiogram-styles/bars"
import { HillsVisualization } from "@/features/remotion/audiogram-styles/hills-visualization"
import { RadialBarsVisualization } from "@/features/remotion/audiogram-styles/radial-bars"
import { WaveVisualization } from "@/features/remotion/audiogram-styles/wave"
import { useAudioData } from "@/features/remotion/hooks/useAudioData"
import { visualizeAudio } from "@remotion/media-utils"
import { useCurrentFrame, useVideoConfig } from "remotion"
import { z } from "zod"

import { cn } from "@/lib/utils"
import { audiogramElementSchema } from "@/lib/validations/element"

export const Audiogram = ({
  audioSrc,
  numberOfSamples,
  el,
  mirroring,
  startFrom,
  ...props
}: {
  numberOfSamples: number
  audioSrc: string
  el: z.infer<typeof audiogramElementSchema>
  mirroring: boolean
  startFrom?: number
} & React.HTMLAttributes<HTMLDivElement>) => {
  const frame = useCurrentFrame()
  const { fps } = useVideoConfig()
  const audioData = useAudioData(audioSrc, {
    retries: 3,
    timeoutInMilliseconds: 3 * 60 * 1000,
  })

  if (!audioData) {
    return null
  }

  const nonAutoWidth = el.transform.width !== "auto" ? el.transform.width : 256
  const nonAutoHeight =
    el.transform.height !== "auto" ? el.transform.height : 256

  const frequencyData = visualizeAudio({
    optimizeFor: "speed",
    fps,
    frame: frame + (startFrom ?? 0),
    audioData,
    numberOfSamples,
  })

  const audiogramComponent = () => {
    // case missing: undefined
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    switch (el.audiogramStyleId) {
      case "bars":
        return (
          <BarsVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            lineThickness={5}
            gapSize={7}
            roundness={2}
            mirroring={mirroring}
            color={el.style.color ?? "#F3B3DC"}
          />
        )
      case "radial-bars":
        return (
          <RadialBarsVisualization
            frequencyData={frequencyData}
            diameter={nonAutoWidth}
            innerRadius={nonAutoWidth / 4}
            color={el.style.color ?? "#DCBC8A"}
          />
        )
      case "hills":
        return (
          <HillsVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            fillColor={["#559B59", "#466CF6", "#E54B41"]}
            copies={3}
            blendMode="screen"
          />
        )
      case "wave":
        return (
          <WaveVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            offsetPixelSpeed={200}
            lineColor={[el.style.color ?? "#EE8482", "teal"]}
            lineGap={(2 * 280) / 8}
            topRoundness={0.2}
            bottomRoundness={0.4}
            sections={Math.max(4, Math.floor(nonAutoWidth / 100))}
          />
        )
      case "mono-hill":
        return (
          <HillsVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            fillColor={[el.style.color ?? "#559B59"]}
          />
        )
      case "replicated-waves":
        return (
          <WaveVisualization
            frequencyData={frequencyData}
            width={nonAutoWidth}
            height={nonAutoHeight}
            offsetPixelSpeed={-100}
            lineColor={el.style.color ?? "#EE8482"}
            lineGap={6}
            topRoundness={0.2}
            bottomRoundness={0.4}
            lines={6}
            sections={Math.max(4, Math.floor(nonAutoWidth / 100))}
          />
        )
    }
  }

  return (
    <div
      {...props}
      className={cn(
        "flex items-center justify-center gap-x-2",
        props.className
      )}
    >
      {audiogramComponent()}
    </div>
  )
}
