"use client"

import { ComponentProps, CSSProperties, ReactNode } from "react"
import { DELAY_RENDER_RETRIES } from "@/features/remotion/constants"
import { OffthreadVideo, Series } from "remotion"
import { z } from "zod"

import { savedInputPropsSchema } from "@/lib/validations/input-props"

type ClippingCompProps<DisablePremounting extends boolean | undefined> = Pick<
  z.infer<typeof savedInputPropsSchema>,
  "clippingBasedProperties" | "isBufferingDisabled"
> & { commonProperties: { sermonVideoUrl: string } } & {
  children?: (props: {
    startFromFrame: number
    endFrame: number
    key: string
  }) => ReactNode
  sequenceProps: Omit<
    Exclude<
      ComponentProps<typeof Series.Sequence>,
      DisablePremounting extends false | undefined
        ? { layout: "absolute-fill" }
        : { layout: "none" | undefined }
    >,
    "key" | "durationInFrames" | "premountFor" | "layout" | "children"
  >
  disablePremounting: DisablePremounting
}

export function ClippingComp<DisablePremounting extends boolean | undefined>({
  clippingBasedProperties: { frameRanges },
  commonProperties: { sermonVideoUrl },
  children,
  isBufferingDisabled,
  sequenceProps,
  disablePremounting,
}: ClippingCompProps<DisablePremounting>) {
  return (
    <Series>
      {frameRanges.map(([startFromFrame, endFrame], i) => {
        const isLastChunk = i === frameRanges.length - 1
        const isLastChunkAndNotTheOnlyChunk = isLastChunk && i !== 0
        const endPadding = isLastChunkAndNotTheOnlyChunk ? 10 : 0

        const durationInFrames = endFrame - startFromFrame + endPadding

        const childrenComponents = children
          ? children({
              startFromFrame,
              endFrame,
              key: i.toString(),
            })
          : null

        return (
          <Series.Sequence
            {...sequenceProps}
            layout={disablePremounting ? "none" : "absolute-fill"}
            key={i.toString()}
            durationInFrames={durationInFrames}
            premountFor={disablePremounting ? undefined : 30 * 5}
            style={
              disablePremounting
                ? undefined
                : {
                    display: "unset",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    ...("style" in sequenceProps
                      ? (sequenceProps.style as CSSProperties)
                      : {}),
                  }
            }
          >
            {childrenComponents ?? (
              <OffthreadVideo
                src={sermonVideoUrl}
                className="z-0 size-full bg-black object-cover object-center"
                style={{
                  zIndex: 1000 - i,
                }}
                startFrom={startFromFrame}
                endAt={endFrame}
                pauseWhenBuffering={!isBufferingDisabled}
                delayRenderTimeoutInMilliseconds={60 * 1000 * 3}
                delayRenderRetries={DELAY_RENDER_RETRIES}
              />
            )}
          </Series.Sequence>
        )
      })}
    </Series>
  )
}
