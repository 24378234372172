import { BaseComp } from "@/features/remotion/base-comp"
import { defaultProps } from "@/features/remotion/default-props"
import { DEFAULT_FPS } from "@/server/constants"
import { getVideoMetadata } from "@remotion/media-utils"
import { Composition, Freeze, getInputProps, random, Video } from "remotion"
import { z } from "zod"

import { SERMON_SHOTS_TO_RENDER } from "@/lib/constants"
import { getPageFromElementTree } from "@/lib/utils/element-tree"
import { inputPropsSchema } from "@/lib/validations/input-props"

const inputProps = getInputProps()

export const Root = () => {
  return (
    <>
      <Composition
        component={BaseComp}
        id="base-comp"
        durationInFrames={
          // TODO: This is a hack; not all callers pass proper schema
          (inputProps as z.infer<typeof inputPropsSchema>).durationInFrames ||
          DEFAULT_FPS * 10
        }
        schema={inputPropsSchema}
        calculateMetadata={({ props }) => {
          const page = getPageFromElementTree(props.commonProperties.elements)
          if (!page) {
            throw new Error("No page found in element tree")
          }
          return {
            width:
              (typeof page.transform.width === "number"
                ? page.transform.width
                : null) ?? 712,
            height:
              (typeof page.transform.height === "number"
                ? page.transform.height
                : null) ?? 712,
            fps: DEFAULT_FPS,
            durationInFrames: props.durationInFrames,
            props: {
              ...props,
              isBufferingDisabled: true,
            },
          }
        }}
        // FIXME: is there another way to have default props?
        defaultProps={defaultProps}
      />

      <Composition
        id="generate-poster"
        fps={DEFAULT_FPS}
        durationInFrames={1}
        component={({
          src,
          aspectRatio,
          ...otherProps
        }: {
          src: string
          frameToShow: number
          chunkToRenderPoster: number
          aspectRatio?: number
        }) => {
          if ("frameToShow" in otherProps) {
            const showBackground = !aspectRatio || aspectRatio < 16 / 9
            return (
              <Freeze frame={otherProps.frameToShow}>
                <div
                  style={{
                    backgroundColor: "#000000",
                    height: 1080,
                    width: 1920,
                    // align the video to the center
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 0,
                  }}
                >
                  {showBackground && (
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor:
                          "rgba(0,0,0, 0.4)" /* Black w/opacity/see-through */,
                        color: "white",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 1,
                        width: "100%",
                        filter: "blur(80px) brightness(0.8)",
                      }}
                    >
                      <Video
                        src={src}
                        muted
                        startFrom={otherProps.frameToShow}
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          padding: "0 0 0 0",
                        }}
                      />
                    </div>
                  )}
                  <Video
                    src={src}
                    muted
                    startFrom={otherProps.frameToShow}
                    style={{
                      objectFit: "contain",
                      height: 1080,
                      padding: "0 0 0 0",
                      // border radius and drop shadow
                      borderRadius: "10px",
                      boxShadow: "0 0 10px 10px rgba(0, 0, 0, 0.5)",
                      zIndex: 2,
                    }}
                  />
                </div>
              </Freeze>
            )
          } else {
            throw new Error("frameToShow is required")
          }
        }}
        height={1080}
        width={1920}
        calculateMetadata={async ({ props }) => {
          const { durationInSeconds, aspectRatio } = await getVideoMetadata(
            props.src
          )
          const durationInFrames = Math.ceil(durationInSeconds * DEFAULT_FPS)
          // Middle of the video
          let frameToShow =
            Math.ceil(durationInFrames / 2) + Math.floor(30 * random(null))

          const chunkSize = Math.floor(
            durationInFrames / SERMON_SHOTS_TO_RENDER
          )
          if ("chunkToRenderPoster" in props) {
            const start = props.chunkToRenderPoster * chunkSize
            const end = start + chunkSize
            frameToShow = Math.floor(random(null) * (end - start + 1)) + start
          }

          return {
            durationInFrames,
            props: {
              ...props,
              frameToShow,
              aspectRatio,
            },
          }
        }}
        defaultProps={{
          frameToShow: 50,
          chunkToRenderPoster: 0,
          aspectRatio: undefined,
          src: "https://s3-editor.postsunday.com/1721419094421/The_Power_of_Surrender__Hannah_s_Story_1718142747560.mp4",
        }}
      />
    </>
  )
}
