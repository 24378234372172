import { z } from "zod"

import { subtitleStyleSchema } from "@/lib/validations/element"

interface SubtitleConfig {
  readonly id: string
  readonly name: string
  style: Partial<z.infer<typeof subtitleStyleSchema>>
  wordsAtATime: number
  thumbnailUrl: string
}

const defaultSubtitleConfig = {
  id: "default" as const,
  name: "Default" as const,
  wordsAtATime: 4,
  style: {
    casing: "uppercase",
    fontFamily: "Inter",
    fontWeight: "900",
    color: "#ffffff",
  },
  thumbnailUrl: "/subtitles/default.png",
} as const satisfies SubtitleConfig

const playfulSubtitleConfig = {
  id: "playful" as const,
  name: "Playful" as const,
  wordsAtATime: 4,
  style: {
    casing: "uppercase",
    fontFamily: "Pacifico",
    fontWeight: "900",
    color: "#ffffff",
  },
  thumbnailUrl: "/subtitles/playful.png",
} as const satisfies SubtitleConfig

const hormoziSubtitleConfig = {
  id: "hormozi" as const,
  name: "Hormozi" as const,
  wordsAtATime: 4,
  style: {
    casing: "uppercase",
    fontFamily: "Poppins",
    fontWeight: "900",
    color: "#ffffff",
    highlightBackgroundColor: "orange",
    highlightTextColor: "#ffffff",
    textShadow:
      "0 0 20px #000,0 0 21px #000,0 0 22px #000,0 0 23px #000,0 0 24px #000,0 0 25px #000,0 0 26px #000,0 0 27px #000,0 0 28px #000,0 0 29px #000",
    disableHighlight: true,
  },
  thumbnailUrl: "/subtitles/hormozi.png",
} as const satisfies SubtitleConfig

export const subtitles = {
  [defaultSubtitleConfig.id]: defaultSubtitleConfig,
  [playfulSubtitleConfig.id]: playfulSubtitleConfig,
  [hormoziSubtitleConfig.id]: hormoziSubtitleConfig,
} as const satisfies Record<string, SubtitleConfig>
